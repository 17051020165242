import React from 'react'
import LeftSection from './LeftSection';
import RightSection from './RightSection';

const HeroSectionHome = () => {
  return (
    <section className='bg-white rounded-b-3xl'>
      <div className="md:flex max-w-page md:mx-12 xxl:m-auto mx-4">
        <LeftSection />
        <RightSection />
      </div>
    </section>
  )
}

export default HeroSectionHome;

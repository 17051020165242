import { IO_API_SERVICE } from "../services/ioApi";

import { getSportsName, setLocationInLocalStorage } from "../util/helperFunctions";
import { fetchMoreVenues } from "./venues/venuesList";

function getCityLatLong(cityKey) {
   let data = {};
   var drinks = {
      0: { lat: 12.97, long: 77.59 },
      1: { lat: 17.38, long: 78.48 },
      2: { lat: 13.08, long: 80.27 },
      3: { lat: 9.93, long: 76.26 },
      4: { lat: 28.7, long: 77.1 },
      5: { lat: 18.52, long: 73.85 }
   };
   data = JSON.stringify(drinks[cityKey]);
   return JSON.parse(data);
}

async function getPhonepeLocation() {
   let data;
   let sdk = await PhonePe.PhonePe.build(PhonePe.Constants.Species.web);
   let result = await sdk.seekPermission([PhonePe.Constants.Permission.LOCATION]);

   let latLong = await sdk
      .getCurrentLocation()
      .then(location => {
         // console.log("PHONEPE LOCATION");
         return location;
      })
      .catch(err => {
         console.log("Error found when fetching location = " + err);
      });
   data = JSON.parse(latLong);
   return await data;
}

function geolocation(dispatch) {
   // console.log("inside geo location");
   return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
         function (position) {

            let location = {
               lat: position.coords.latitude,
               long: position.coords.longitude
            };

            resolve(location);
         },
         function (error) {
            dispatch({
               type: "SWITCH_LOADER",
               payload: false
            });

            dispatch({
               type: "INFO_MODAL",
               payload: {
                  open: true,
                  data: "Please Grant Geo Location Permission  ",
                  text: "text-black",
                  intent: "bg-primary"
               }
            });
            console.error("Error Code = " + error.code + " - " + error.message);
         }
      );
      // window.navigator.geolocation.getCurrentPosition(position => {
      //    let location = {
      //       lat: position.coords.latitude,
      //       long: position.coords.longitude
      //    };

      //    resolve(location);
      // });
   });
}

export async function currentLocation(dispatch) {
   const location = (await geolocation(dispatch)) || false;
   return location;
}

async function getGooglePayLoc() {
   let data = await microapps
      .getCurrentLocation()
      .then(response => {
         // console.log("getCurrentLocation response: ", response);
         return response;
      })
      .catch(error => {
         console.error("An error occurred: ", error);
      });

   return await data;
}

function getPaytmLocation() {
   return new Promise((resolve, reject) => {
      JSBridge.call("paytmGetLocation", {}, function (result) {
         // console.log("paytm location", JSON.stringify(result))

         resolve(result.data);
      });
   });
}

// find lat, long of location
export const findMyLocation = async (oldLatLng, sports, allsports, dispatch, router, cb) => {
   // let isPhonePe;
   // let user;
   // let paytm, googlePay;
   // try {
   //    user = window.navigator.userAgent;
   //    isPhonePe = user.match(/phonepe-webview/g);
   //    paytm = user.match(/AppContainer/i);
   //    googlePay = user.match(/GPay-Microapps/g);
   // } catch (err) {
   //    console.log('Error here', err.toString());
   // }

   //  if (!relode&&oldLatLng >= 0 && oldLatLng <= 6 && oldLatLng.constructor !== Array) {
   //    locationRes = await getCityLatLong(oldLatLng);

   //    oldLatLng = locationRes;
   //  }
   //    if (isPhonePe) {
   //    locationRes = await getPhonepeLocation();
   //  } else if (paytm) {
   //    locationRes = await geolocation();
   //    console.log("paytmppp--", locationRes);
   //  } else if (googlePay) {
   //    locationRes = await getGooglePayLoc();
   //    console.log("google--", locationRes);
   //  }
   //  else if (relode){
   //  locationRes = oldLatLng
   //  console.log({ locationRes });
   //  }
   //  else {

   //  }
   let locationRes = (await geolocation(dispatch)) || false;

   let lat = locationRes.lat || locationRes.latitude;
   let long = locationRes.long || locationRes.longitude;

   // dispatch({ type: 'FETCH_LOCATION', payload: true });
   // if (navigator.geolocation) {
   // navigator.geolocation.getCurrentPosition(position => {
   // calling google api to get location name from coords
   return IO_API_SERVICE.getAddress(lat, long)
      .then(response => {
         let parsedResult = response?.data;

         dispatch({ type: "LAT_LONG", payload: [lat, long] });

         getSubLocality(parsedResult.results, function (sublocality) {
            dispatch({ type: "CURRENT_LOCATION", payload: sublocality });
            dispatch({ type: "SHOW_SELECTED", payload: "hide" });
            // dispatch({ type: 'FETCH_LOCATION', payload: false });

            checkIfLocationChange(
               sublocality,
               lat,
               long,
               oldLatLng,
               sports,
               allsports,
               dispatch,
               router,
               cb
            );
            dispatch({
               type: "SWITCH_LOADER",
               payload: false
            });
            // saveCurrentLocation(sublocality, lat, long);
         });
      })
      .catch(err => {
         dispatch({
            type: "SWITCH_LOADER",
            payload: false
         });
         console.log({ err });
         // dispatch({ type: 'FETCH_LOCATION', payload: false });
      });

   //   function (error) {
   //     dispatch(dispatchAction(FETCH_LOCATION, false))
   //     if (error.code == error.PERMISSION_DENIED) {
   //       dispatch(dispatchAction(SHOW_SELECTED, 'hide'))
   //     }
   //     dispatch(dispatchAction(LOCATION_DENIED, true))
   //   },
   // {
   //   timeout: 30000,
   //   enableHighAccuracy: true,
   //   maximumAge: 75000
   // }
   // )
   // }
   // }
};

export function getVenuesByLocation(
   address,
   lat,
   lng,
   oldLatLng,
   sports,
   allsports,
   dispatch,
   router,
   cb
) {
   var addressLowercase = address.toLowerCase();

   dispatch({ type: "LAT_LONG", payload: [lat, lng] });
   dispatch({ type: "CURRENT_LOCATION", payload: address });
   dispatch({ type: "SHOW_SELECTED", payload: "hide" });

   checkIfLocationChange(
      addressLowercase,
      lat,
      lng,
      oldLatLng,
      sports,
      allsports,
      dispatch,
      router,
      cb
   );
   // saveCurrentLocation(addressLowercase, lat, lng);
}

// get sublocality from google maps api response
function getSubLocality(results, callback) {
   let sublocality = "";
   results[0].address_components.some(result => {
      result.types.some(locality => {
         if (
            (locality == "sublocality_level_1" || locality == "locality") &&
            result.long_name !== "[no name]"
         ) {
            sublocality = result.long_name;
            return true;
         }
      });

      if (sublocality) {
         return true;
      }
   });
   callback(sublocality.toLowerCase());
}

function checkIfLocationChange(
   address,
   lat,
   lng,
   oldLatLng,
   sports,
   allSports,
   dispatch,
   router,
   cb
) {
   const city = address;
   setLocationInLocalStorage(city, lat, lng);

   try {
      if (lat != oldLatLng[0] || lng != oldLatLng[1]) {
         dispatch({ type: "SET_VENUE_LIST", payload: "" });
      }
   } catch (err) {}
   var newAddress = address.split(" - ").join(" ").split(" ").join("-").split(",")[0];
   var sportsName = getSportsName(allSports, sports);

   var sportsUrl = sportsName.length > 0 ? sportsName.join("-") : "all";

   // const body = {
   //    page: 0,
   //    lat: lat,
   //    lng: lng,
   //    sportId: []
   // };
   // fetchMoreVenues(body, [], dispatch);

   if (router.asPath.includes("experiences")) {
      cb(
         "/experiences/" + newAddress + "/" + router.query.category + "/" + sportsUrl.toLowerCase()
      );
   } else if (router.asPath.includes("/venues/")) {
      cb("/venues/" + newAddress + "/sports/" + sportsUrl.toLowerCase());
   } else if (router.asPath.includes("activities")) {
      const query = router.query || {};
      query.location = newAddress;
      const queryStr = Object.keys(query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`).join('&');
      cb(router.pathname + "?" + queryStr);
   }
}

export function saveCurrentLocation(address, lat, lng) {
   var body = {
      key: address.split(",")[0].split(" - ").join(" "),
      lat: lat,
      lng: lng
   };

   return Api.saveLocation(body).then(response => {
      let parsedResult = JSON.parse(response.text);
   });
}

export function backToListing(address, sports, allSports, cb) {
   var newAddress = address.replace(" - ", " ").split(" ").join("-").split(",")[0];
   var sportsName = getSportsName(allSports, sports);
   var sportsUrl = sportsName.length > 0 ? sportsName.join("-") : "all";
   cb("/venues/" + newAddress + "/sports/" + sportsUrl.toLowerCase());
}

import React, { useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic';

// import LocationInput from '../Common/LocationInput';
import { getLocationFromLocalStorage } from '../../util/helperFunctions';

const LocationInput = dynamic(() => import('../Common/LocationInput'), {
  ssr: false
})

const LeftSection = () => {

  const storedLocation = getLocationFromLocalStorage();

  const [isSticky, setIsSticky] = useState(false);

  const LocationRef = useRef();

  const handleScroll = () => {
    const rect = LocationRef.current.getBoundingClientRect();
    if (window?.scrollY > rect.top) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    if (document) {
      const navLocation = document.getElementById("home-location");
      if (navLocation) {
        if (isSticky) {
          navLocation.style.display = 'block';
        } else {
          navLocation.style.display = 'none';
        }
      }
    }
  }, [isSticky])

  useEffect(() => {
    if (document) {
      const navLocation = document.getElementById("home-location");
      if (navLocation) {
        navLocation.style.display = 'none';
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className='md:w-1/2 flex flex-col justify-center'>
      <div className={`hidden md:block top-4 z-0 ${isSticky ? 'fixed ml-32' : ''}`} ref={LocationRef}>
        <LocationInput storedLocation={storedLocation} homePage />
      </div>
      <h1 className='md:text-3xl text-2xl md:leading-[1.61] leading-[2] font-black md:w-3/5 mt-6'>FIND PLAYERS & VENUES NEARBY</h1>
      <p className="text-mute_text font-medium md:w-2/3 leading-[1.5] mt-6 text-xl">Seamlessly explore sports venues and play with sports enthusiasts just like you!</p>
    </div>
  )
}

export default LeftSection;

import React, { useEffect, useState } from "react";
import { IO_API_SERVICE } from "../../../services/ioApi";
import VenueCardV2 from "../../Card/VenueCardV2";
import ViewAllButton from "../ViewAllButton";
import { getLocationFromLocalStorage } from "../../../util/helperFunctions";
import { useRouter } from "next/router";
import CarouselWithArrowsAndScroll from "../CarouselWithArrowAndScroll";
import { UseStateValue } from "../../../state";
import { isEmpty } from "lodash";

const VenueListing = ({ latlng = {}, allSports }) => {
  const router = useRouter();
  const [{ location }, _] = UseStateValue();
  const [venues, setVenues] = useState({
    venueList: [],
    isLoading: false
  });

  useEffect(() => {
    const storedLocation = getLocationFromLocalStorage();
    if (!isEmpty(storedLocation) || (location?.locationLatLon?.length)) {

      const lat = storedLocation?.lat || location?.locationLatLon[0];
      const lng = storedLocation?.lng || location?.locationLatLon[1];

      const data = {
        sort: 0,
        avgRatingSort: 0,
        lat,
        lng,
        category: "venue"
      };
  
      setVenues({ ...venues, isLoading: true })
  
      IO_API_SERVICE.getHomePageVenues(data).then(res => {
        if (res.requestStatus === 1) {
          let venueList = res.data?.venueList;
          setVenues({ venueList, isLoading: false });
        }
      }).catch((err) => {
        console.error({ err });
        setVenues({ venueList: [], isLoading: false });
      });
    }
  }, [location]);


  if (venues?.venueList?.length) {
    const venueProps = venues?.venueList?.map((venue, index) => ({
      venue: venue,
      index: index,
      pagePath: `/venues/${venue.city || 'bangalore'}/${venue?.activeKey}`,
      allSports: allSports
    }));

    
    const handleClick = () => {
      const storedLocation = getLocationFromLocalStorage();
      router.push(`/venues/${storedLocation.city.toLowerCase() || 'bangalore'}`);
    }

    return (
      <div>
        <div className="flex justify-between font-bold md:px-12 px-4">
          <h2 className="md:leading-9 md:text-2xl text-xl">Book Venues</h2>
          <ViewAllButton text="SEE ALL VENUES" onClick={handleClick} />
        </div>
        <div className="flex mt-6">
          <CarouselWithArrowsAndScroll>
            {venueProps.map((venue, idx) => (
              <div key={idx} className="first:ml-4 ml-4 last:mr-4 md:first:ml-12 md:ml-6 md:last:mr-12 mb-6">
                <VenueCardV2 {...venue} />
              </div>
            ))}
          </CarouselWithArrowsAndScroll>
        </div>
      </div>
    );
  }
};

export default VenueListing;

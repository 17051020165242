import React, { useEffect, useState } from 'react';

const RingText = () => {
  const text = 'YOURONESTOPPLATFORM';

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const rotationDegree = (window.scrollY / 6) % 360;
      setRotation(rotationDegree);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative flex items-center justify-center">
      {text.split('').map((char, index) => (
        <div
          key={index}
          className="absolute uppercase ml-4 h-52 font-medium"
          style={{
            transform: `rotate(${(index * 360) / text.length + rotation}deg)`,
          }}
        >
          {char}
        </div>
      ))}
    </div>
  );
};

export default RingText;

import React from 'react'

import { POPULAR_SPORTS } from '../../constants/homePageConstants';
import { getLocationFromLocalStorage } from '../../util/helperFunctions';
import Link from 'next/link';

const PopularSports = () => {

  const storedLocation = getLocationFromLocalStorage();

  const location = storedLocation?.city || 'bangalore';

  return (
    <div className='mt-8 min-h-[140px]'>
      <h3 className='md:px-12 px-4 font-bold leading-9 md:text-2xl text-xl'>Popular Sports</h3>
      <div className='flex mt-6 gap-6 overflow-x-auto no-scrollbar md:justify-between'>
        {POPULAR_SPORTS.map((sport, idx) => {
          return (
            <Link key={idx} href={`/venues/${location}/sports/${sport.key}`}>
              <div className='relative cursor-pointer first:ml-4 last:mr-4 md:first:pl-0 md:last:pr-0 aspect-[185/246] shadow-card rounded-2xl mb-6 md:mb-12 overflow-hidden first:md:ml-12 last:md:mr-12 min-w-[180px] md:min-w-[100px] min-h-[120px]'>
                <div className='absolute text-white font-bold bottom-0 ml-4 mb-6 md:mb-4 leading-7'>{sport.name}</div>
                <img src={`https://playo-website.gumlet.io/playo-website-v3/popular_sports/${sport.key}_new.png`} alt={sport.name} key={sport.id} className='min-w-[180px] md:min-w-[100px] min-h-[120px]' />
              </div>
            </Link>
          )}
        )}
      </div>
    </div>
  )
}

export default PopularSports;

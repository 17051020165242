import Image from 'next/image';
import React from 'react'

const BlogCard = ({ data }) => {
  return (
    <a
      href={data.link}
      target="_blank"
      className="flex overflow-hidden cursor-pointer max-w-[250px] relative w-full min-w-[250px] flex-col space-y-4 items-start justify-start rounded-[16px]"
    >
      <div className="flex  rounded-[16px] relative bg-gray-300 flex-row items-center space-x-1 h-[140px] w-full justify-start">
        <Image
          src={data.image}
          className="rounded-[16px]"
          objectFit="cover"
          layout="fill"
          alt={data.text}
        />
      </div>
      <div className=" w-full p-1 flex flex-col items-start justify-between">
        {data.source ? <div className=" w-full flex flex-row items-start justify-between">
          <span className="font-medium hover:text-green-800 text-[14px] leading-6 text-primary uppercase tracking-wider">
            {data.source}
          </span>
        </div> : null}
        <div className=" w-full my-2 flex flex-row items-start justify-between">
          <span className="font-bold leading-8 text-[20px] text-[#3B4540] overflow-hidden text-ellipsis line-clamp-2">{data.text}</span>
        </div>

        <div className="font-medium break-words w-full leading-5 tracking-wide text-[14px] text-[#3B4540] overflow-hidden text-ellipsis line-clamp-2">
          {data.subText}
        </div>
        <div className="flex mt-3 text-[#758A80] flex-row items-start text-sm font-medium justify-start">
          {data.date} | {data.author}
        </div>
      </div>
    </a>
  )
}

export default BlogCard;

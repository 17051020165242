import React from 'react';
import InfiniteScroll from '../Common/InfiniteScroll';
import Link from 'next/link';

const ImageDisplay = ({ banner }) => {
  if (!banner.enabled) return null;

  if (banner.link) {
    return (
      <Link href={banner.link}>
        <img src={banner.imageUrl} height={212} width={'100%'} alt={banner.description} className='rounded-3xl h-[212px] cursor-pointer' />
      </Link>
    );
  }

  return <img src={banner.imageUrl} height={212} width={'100%'} alt={banner.description} className='rounded-3xl h-[212px]' />;
}

const MarketingBanner = ({ banners }) => {
  return (
    <div className='flex gap-8 h-[212px] overflow-hidden grow'>
      <div className='animate-infinite-scroll h-[212px] flex gap-8'>
        {banners.map((banner, idx) => {
          return <ImageDisplay banner={banner} key={idx} />
        })}
        {banners.map((banner, idx) => {
          return <ImageDisplay banner={banner} key={idx} />
        })}
      </div>
    </div>
  );
};

export default MarketingBanner;

import React, { useRef, useState, useEffect } from 'react';
import { ArrowButton } from './Carousel';

const CarouselWithArrowsAndScroll = ({ children }) => {
  const carouselRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  const scrollBy = (offset) => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: offset, behavior: 'smooth' });
      setTimeout(checkScrollButtons, 150); // Check after the scroll animation
    }
  };

  const handleNext = () => {
    scrollBy(carouselRef.current.firstElementChild.clientWidth);
  };

  const handlePrev = () => {
    scrollBy(-carouselRef.current.firstElementChild.clientWidth);
  };

  useEffect(() => {
    const handleResize = () => {
      checkScrollButtons();
    };

    window.addEventListener('resize', handleResize);
    checkScrollButtons(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      checkScrollButtons();
    };

    carouselRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div
        ref={carouselRef}
        className="flex no-scrollbar"
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
      >
        {children}
      </div>
      {(canScrollLeft || canScrollRight) ? <div className='flex justify-center items-center mb-6 gap-3'>
        <ArrowButton onClick={handlePrev} disabled={!canScrollLeft}>
          <img src='https://playo-website.gumlet.io/playo-website-v3/icons/right_arrow.png' alt='left' width={20} height={20} className='rotate-180' />
        </ArrowButton>
        <ArrowButton onClick={handleNext} disabled={!canScrollRight}>
          <img src='https://playo-website.gumlet.io/playo-website-v3/icons/right_arrow.png' alt='right' width={20} height={20} />
        </ArrowButton>
      </div> : null}
    </div>
  );
};

export default CarouselWithArrowsAndScroll;

export const SEODATA = {
  title: "Playo – Connect. Play. Track.",
  description:
     "Join the world’s largest sports community, connecting millions of people to the sports they love. Playo is the only app you’ll ever need to play & boost your game. ",
  url: "https://playo.co",
  type: "website",
  imgUrl: "https://playo-website.gumlet.io/playo/playo_meta_image.png?auto=compress,format"
};

export const CITIES = [
  "bengaluru",
  "bangalore",
  "chennai",
  "hyderabad",
  "pune",
  "vijayawada",
  "mumbai",
  "delhi-ncr",
  "delhi",
  "noida",
  "visakhapatnam",
  "guntur",
  "kochi",
  "dubai",
  "doha"
];

export const POPULAR_SPORTS = [
  {
    id: 1,
    name: "Badminton",
    key: "badminton",
  },
  {
    id: 2,
    name: "Football",
    key: "football",
  },
  {
    id: 3,
    name: "Cricket",
    key: "cricket",
  },
  {
    id: 4,
    name: "Swimming",
    key: "swimming",
  },
  {
    id: 5,
    name: "Tennis",
    key: "tennis",
  },
  {
    id: 6,
    name: "Table Tennis",
    key: "table_tennis",
  }
];

export const PARTNER_WITH_US = {
  PARTNER_IMAGES: [
    {
      name: 'C-DAC',
      imageURL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/CDac.png'
    },
    {
      name: 'Groww',
      imageURL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/groww.png'
    },
    {
      name: 'Deloitte',
      imageURL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/deloitte.png'
    },
    {
      name: 'Swiggy',
      imageURL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/swiggy.png'
    },
    {
      name: 'Accenture',
      imageURL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/accenture.png'
    },
    {
      name: 'Switchgear and control technics pvt ltd',
      imageURL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/Switchgear.png'
    }
  ],
  PARTNER_LOGOS: [
    {
      name: 'WSP Global',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/wsp_global_logo.svg',
      width: '69',
      height: '39'
    },
    {
      name: 'Microsoft',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/microsoft_logo.svg',
      width: '129',
      height: '28'
    },
    {
      name: 'Google',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/google_logo.svg',
      width: '93',
      height: '28'
    },
    {
      name: 'KPMG',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/kpmg_logo.svg',
      width: '69',
      height: '28'
    },
    {
      name: 'Accenture',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/accenture_logo.svg',
      width: '109',
      height: '28'
    },
    {
      name: 'Deloitte',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/deloitte_logo.svg',
      width: '105',
      height: '28'
    },
    {
      name: 'Swiggy',
      URL: 'https://playo-website.gumlet.io/playo-website-v3/corporate-partners/swiggy_logo.svg',
      width: '96',
      height: '28'
    }
  ]
};

export const BLOGDATA = [
  {
     text: "Learn Volleyball in 5!",
     date: "SEPTEMBER 26, 2018",
     author: "PLAYO",
    //  source: "#HumansOfPlayo",
     image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2018/09/83de99b7a72b7e0b9ff755c60efc8623.gif",
     link: "https://blog.playo.co/here-are-the-dimensions-of-a-volleyball-court/",
     subText: "The most simplified Volleyball manual you’ll need"
  },
  {
    text: "Names Celebrated by Cricket",
    date: "MARCH 3, 2016",
    author: "SHERYL THOMAS",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2016/03/9836_mr.jpg",
    link: "https://blog.playo.co/legends-of-cricket/",
    subText: "Understand what it takes to become a Cricket icon!"
  },
  {
    text: "Easy-to-Learn Badminton Signals",
    date: "AUGUST 2, 2019",
    author: "NIDHI PATEL",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2019/08/Venues-Blog-cover-Image.png",
    link: "https://blog.playo.co/badminton-hand-signals-and-what-they-mean/",
    subText: "Get a peek of game-basics that’ll surely come in handy!"
  },
  {
    text: "A Spectator’s Tournament Guide",
    date: "JANUARY 12, 2022",
    author: "PLAYO",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2017/09/featured-image-7.jpg",
    link: "https://blog.playo.co/spectators-guide-what-to-wear-and-bring-to-a-golf-tournament/",
    subText: "This is how you go prepped for a Golf tournament!"
  },
  {
    text: "Take Football Shots Like a ‘PRO’",
    date: "APRIL 15, 2017",
    author: "ARJUN THOMAS",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2017/04/4.png",
    link: "https://blog.playo.co/the-5-different-types-of-kicks-in-football/",
    subText: "Learn what it takes to master popular Football shots"
  },
  {
    text: "Adventure-ing in Bangalore",
    date: "APRIL 26, 2017",
    author: "PLAYO",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2017/04/water-zorbing-in-bangalore.jpg",
    link: "https://blog.playo.co/7-places-in-bangalore-for-fun-sports/",
    subText: "Understand how & where to look for Adventures in B’lore"
  },
  {
    text: "The Best Comebacks in Sport",
    date: "MARCH 13, 2017",
    author: "ANUBHAV RODA",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2017/03/dhoni-blog-coverimage1.jpg",
    link: "https://blog.playo.co/10-inspiring-sports-stories-from-being-a-failure-to-success/",
    subText: "10 icons who used failure as stepping stones to success"
  },
  {
    text: "Untouchable Cricket Records",
    date: "APRIL 13, 2022",
    author: "PLAYO",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2021/03/10-Cricket-Records-That-are-Nearly-Impossible-to-Break.jpg",
    link: "https://blog.playo.co/17-cricket-records-that-are-nearly-impossible-to-break/",
    subText: "Get a peek into records set after years of perseverance"
  },
  {
    text: "Learn Badminton in 5!",
    date: "JULY 7, 2022",
    author: "PRATHEEK SURYADEV",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2022/09/Highcompressed_578856886-scaled.jpg",
    link: "https://blog.playo.co/all-about-badminton/",
    subText: "All the need-to-knows before you start Badminton"
  },
  {
    text: "The Best Sports to Burn Calories",
    date: "OCTOBER 3, 2017",
    author: "KAMESWARI KOVVALI",
    // source: "",
    image: "https://d20rwxqzk8p5vr.cloudfront.net/wp-content/uploads/2017/10/fat-burning-sports.jpg",
    link: "https://blog.playo.co/top-10-fat-burning-sports/",
    subText: "Understand how to get rid of rigid body fat, the fun way"
  }
];

export default {};

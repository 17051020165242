import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import {
  getLocationFromLocalStorage,
  getSportById,
  truncateString
} from "../../util/helperFunctions";

export default function VenuesCard({ venue, allSports }) {

  let icons = [];

  venue?.sports.some((sport, i) => {
    let sportData = getSportById(allSports.list, sport);
    icons.push(
      <div key={i} className="relative w-8 h-8 ">
        <Image
          layout="fill"
          style={{ filter: "brightness(0) invert(1)" }}
          src={`${sportData?.v2GrayIcon}?q=20`}
          alt={sportData.name}
          key={i}
          priority={true}
          quality={50}
        />
      </div>
    );
    if (i == 1) {
      return true;
    }
  });

  let totalSports = venue?.sports.length;
  let remain =
    totalSports - 2 > 0 ? (
      <span className="md:text-xs text-white text-[10px]">{" + " + (totalSports - 2) + " more"}</span>
    ) : (
      <span />
    );

  const myLoader = ({ src }) => {
    return `${src}?mode=crop&crop=smart&h=200&width=450&q=40&format=webp`;
  };

  const router = useRouter();
  const navigateToDetails = async () => {
    const storedLocation = getLocationFromLocalStorage();
    let path = `/venues/${storedLocation.city.toLowerCase()}/${venue?.keywords[0]?.key}`;
    router.push(path);
  };

  return (
    <div
        onClick={() => {
          navigateToDetails();
        }}
        className="shadow-card flex cursor-pointer overflow-hidden max-w-[340px] relative w-full min-w-[316px] flex-col space-y-3 bg-white items-start p-2 justify-start border rounded-[16px] border-[#E3E8E6] h-full"
    >
      <div className="relative w-full h-36 rounded-lg sm:h-36 md:h-36">
        {venue && venue?.images && venue?.images[0] ? (
          <Image
            className="bg-opacity-50 rounded-lg bg-surface"
            loader={myLoader}
            layout="fill"
            objectFit="cover"
            quality={30}
            priority={true}
            src={`${venue && venue?.images && venue?.images[0]?.url}`}
            alt={venue.name}
          />
        ) : (
          <div className="h-36 w-full relative rounded-lg bg-gray-300"></div>
        )}
        <div className="flex bg-opacity-20 absolute bottom-0 flex-row items-center">
          {icons}
          {remain}
        </div>
        {venue?.bnpl && (
          <div className="absolute top-0 my-2 left-2 bg-gradient-to-r rounded-md">
            <div className="rounded-md font-medium text-center tracking-wider px-2 text-white bg-black bg-opacity-50 text-xs md:text-base leading-[18px]">
                Book Now & Pay Later
            </div>
          </div>
        )}
        {venue && venue?.featured && (
          <div className="absolute bottom-0 my-1 right-0  bg-gradient-to-r rounded-md p-[1px]  mr-1 from-slate-800 via-slate-500 to-white">
            <div className="rounded-md md:text-sm font-medium text-center tracking-wider px-2 text-white bg-black bg-opacity-50 text-[10px]">
              FEATURED
            </div>
          </div>
        )}
      </div>
      <div className=" w-full  flex flex-col items-start justify-between">
        <div className=" w-full  flex flex-row items-start justify-between">
          {" "}
          <span className="font-bold text-[16px] text-on_background truncate">{truncateString(venue.name, 22)}</span>
          <div className="text-primary p-1 font-semibold text-[14px] bg-primary bg-opacity-20 rounded-md">
            {venue.avgRating.toFixed(2)}
            <span className="font-normal md:text-xs text-[10px] leading-5"> ({venue.ratingCount})</span>
          </div>
        </div>
        <div className=" w-full mt-1  flex flex-row items-start justify-between">
          <span className="font-medium md:text-sm text-xs text-mute_text md:leading-[22px] leading-[18px]">
            {truncateString(venue?.address, 22)} (~{venue?.distance.toFixed(2)} Kms)
          </span>
        </div>
      </div>
      {venue.offerName || venue.price ? <div className="w-full border-t flex items-center justify-between pt-2">
        {venue.offerName ? (
          <div className="flex flex-row items-center justify-start">
            {" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M20.0013 12.0007C20.0013 10.87 19.428 9.87333 18.556 9.28533C18.7567 8.25333 18.4573 7.14333 17.658 6.34333C16.8587 5.544 15.7487 5.24467 14.716 5.44533C14.128 4.57333 13.1313 4 12.0007 4C10.87 4 9.87333 4.57333 9.28533 5.44533C8.25333 5.24467 7.14267 5.544 6.34333 6.34333C5.544 7.14267 5.24467 8.25267 5.44533 9.28533C4.57333 9.87333 4 10.87 4 12.0007C4 13.1313 4.57333 14.128 5.44533 14.716C5.24467 15.748 5.544 16.8587 6.34333 17.658C7.14267 18.4573 8.25267 18.7567 9.28533 18.556C9.87333 19.428 10.87 20.0013 12.0007 20.0013C13.1313 20.0013 14.128 19.428 14.716 18.556C15.748 18.7567 16.8587 18.4573 17.658 17.658C18.4573 16.8587 18.7567 15.7487 18.556 14.716C19.428 14.128 20.0013 13.1313 20.0013 12.0007ZM9.33467 10.0007C9.33467 9.63267 9.63333 9.334 10.0013 9.334C10.3693 9.334 10.668 9.63267 10.668 10.0007C10.668 10.3687 10.3693 10.6673 10.0013 10.6673C9.63333 10.6673 9.33467 10.3687 9.33467 10.0007ZM11.1787 14.6673H9.624L12.824 9.334H14.3787L11.1787 14.6673ZM14.0013 14.6673C13.6333 14.6673 13.3347 14.3687 13.3347 14.0007C13.3347 13.6327 13.6333 13.334 14.0013 13.334C14.3693 13.334 14.668 13.6327 14.668 14.0007C14.668 14.3687 14.3693 14.6673 14.0013 14.6673Z"
                  fill="#00B562"
              />
            </svg>
            <span className="font-medium md:text-sm text-xs text-on_background leading-6">{venue.offerName}</span>
          </div>
        ) : 
          venue.price ? <div className="font-medium text-xs md:text-sm text-secondary leading-6">
            Price Starting From
          </div> : null
        }
        {venue.price ? <span className="font-bold text-xs md:text-sm">Rs {venue.price} Onwards</span> : null}
      </div> : null}
    </div>
  );
}

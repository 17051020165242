import React from 'react';
import InfiniteScroll from '../Common/InfiniteScroll';

const SpotLightBanner = ({ item }) => {

  if (item.isSpotLightHeader) {
    return <img src={item.imageURL} width={'100%'} height={'100%'} className='min-w-[143px] h-[400px] w-[143px] pl-4 md:pl-0' alt='Spotlight' />
  }

  return (
    <a href={item.redirectLink} rel="noopener noreferrer" target="_blank">
        <img src={item.journeyImage} className='rounded-2xl min-w-[316px] h-[400px] w-[316px]' loading='lazy' width={'100%'} height={'100%'} alt={item.journeyTitle} />
    </a>
  );
};

const SpotLight = ({ spotLightBanners }) => {

  if (!spotLightBanners) {
    return null;
  }

  const banners = [
    {
      isSpotLightHeader: true,
      imageURL: 'https://playo-website.gumlet.io/playo-website-v2/web-home-screen/spotlight2.png'
    },
    ...spotLightBanners
  ];

  return (
    <section className="mt-7">
      <InfiniteScroll gap="4" classNames="overflow-hidden no-scrollbar">
        {banners.map((banner, idx) => (
          <SpotLightBanner item={banner} key={idx} />
        ))}
      </InfiniteScroll>
    </section>
  )
}

export default SpotLight;

import axios from 'axios';

// extract token from cookie and set it at Auth
const axiosInstance = axios.create({
  timeout: 20000
});

// function interceptRequest(config) {
//   if (typeof window !== 'undefined') {
//     const jwtToken = getUserToken();
//     config.headers.Authorization = jwtToken ? `JWT ${jwtToken}` : null;
//   }
//   return config;
// }

// axiosInstance.interceptors.request.use(
//   config => interceptRequest(config),
//   error => Promise.reject(error)
// );

// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 401) {
//       axiosInstance.defaults.headers.Authorization = null;
//       removeUserToken();
//       removeUserData();
//     }
//     return Promise.reject(error);
//   }
// );

export { axiosInstance };

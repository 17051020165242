import Head from "next/head";
import React from "react";

const Seo = ({
   title,
   description,
   url,
   type,
   imgUrl,
   twitterCardType = "app",
   schemas,
   noIndex = false,
   key = "test",
   breadCrumb
}) => {
   return (
      <Head>
         <title>{title}</title>
         <meta name="description" content={description} />
         <link rel="canonical" href={url} />
         <meta property="og:url" content={url} />
         <meta property="og:type" content={type} />
         <meta property="og:title" content={title} />
         <meta property="og:description" content={description} />
         <meta property="og:image" content={imgUrl} />
         <meta property="og:image:alt" content={title} />
         <meta name="twitter:card" content={twitterCardType} />
         <meta name="twitter:title" content={title} />
         <meta name="twitter:description" content={description} />
         <meta name="twitter:image" content={imgUrl} />
         {noIndex ? <meta name="robots" content={noIndex} /> : null}
         {schemas ? (
            <script
               key={`person-schema`}
               type="application/ld+json"
               dangerouslySetInnerHTML={{ __html: JSON.stringify(schemas) }}
            />
         ) : null}
         {breadCrumb ? (
            <script
               key={`breadcrumb-schema`}
               type="application/ld+json"
               dangerouslySetInnerHTML={{ __html: JSON.stringify(breadCrumb) }}
            />
         ) : null}
      </Head>
   );
};

export default Seo;

import React from 'react'
import { BLOGDATA } from '../../constants/homePageConstants';
import BlogCard from './BlogCard';
import CarouselWithArrowsAndScroll from './CarouselWithArrowAndScroll';

const Blogs = () => {
  return (
    <div>
      <h3 className='font-bold leading-9 mx-4 md:mx-12 md:text-2xl text-xl mb-6'>Blogs to Keep You Fit!</h3>
      <div className='flex gap-6'>
        <CarouselWithArrowsAndScroll>
          {BLOGDATA.map((data, idx) => (
            <div key={idx} className='first:ml-4 ml-4 last:pr-4 md:first:ml-12 md:ml-6 md:last:pr-12 mb-6'>
              <BlogCard data={data} />
            </div>
          ))}
        </CarouselWithArrowsAndScroll>
      </div>
    </div>
  )
}

export default Blogs;

import React, { useState } from 'react'

const InfiniteScroll = ({ children, gap = '', classNames = 'overflow-hidden' }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);
  return (
    <div className={`w-full inline-flex flex-nowrap md:mt-6 mt-6 ${classNames}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={`flex items-center justify-center md:justify-start gap-${gap} animate-infinite-scroll ml-${gap} ${isHovering ? 'animate-paused' : ''}`}>
        {children}
      </div>
      <div className={`flex items-center justify-center md:justify-start gap-${gap} animate-infinite-scroll ml-${gap} ${isHovering ? 'animate-paused' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default InfiniteScroll;

import React from 'react'

const ViewAllButton = ({ text, onClick }) => {
  return (
    <button className="text-primary flex justify-center items-center gap-2 font-bold leading-6" onClick={onClick}>
      {text}
      <img src='https://playo-website.gumlet.io/playo-website-v3/icons/right_arrow_green.png' alt='right' width={16} height={16} />
    </button>
  )
}

export default ViewAllButton;

export const sportFacilities = [
   { sport: "Cricket", facility: "Ground" },
   { sport: "Basketball", facility: "Court" },
   { sport: "Football", facility: "Ground" },
   { sport: "Tennis", facility: "Court" },
   { sport: "Table Tennis", facility: "Club" },
   { sport: "Badminton", facility: "Court" },
   { sport: "Pool", facility: "Table" },
   { sport: "Volleyball", facility: "Court" },
   { sport: "Yoga", facility: "Studio" },
   { sport: "Workout", facility: "Gym" },
   { sport: "Running", facility: "Track" },
   { sport: "Skates", facility: "Rink" },
   { sport: "Cycling", facility: "Track" },
   { sport: "Squash", facility: "Court" },
   { sport: "Paintball", facility: "Arena" },
   { sport: "Laser Tag", facility: "Arena" },
   { sport: "Bowling", facility: "Alley" },
   { sport: "Motor Sports", facility: "Track" },
   { sport: "Golf", facility: "Course" },
   { sport: "Combat Sports", facility: "Ring" },
   { sport: "Target Sports", facility: "Range" },
   { sport: "Hockey", facility: "Field" },
   { sport: "Equestrian", facility: "Arena" },
   { sport: "Fun Football", facility: "Field" },
   { sport: "Board Games", facility: "Table" },
   { sport: "Paddle Tennis", facility: "Court" },
   { sport: "Rugby", facility: "Field" },
   { sport: "Adventure Sports", facility: "Arena" },
   { sport: "Ultimate Frisbee", facility: "Field" },
   { sport: "Sepak", facility: "Court" },
   { sport: "Kabaddi", facility: "Court" },
   { sport: "Pokemon-Go", facility: "Mobile" },
   { sport: "Foosball", facility: "Table" },
   { sport: "Sporty Birthday", facility: "Field" },
   { sport: "Scuba Diving", facility: "Pool" },
   { sport: "Rifle Shooting", facility: "Range" },
   { sport: "Futsal", facility: "Court" },
   { sport: "Beach Volleyball", facility: "Court" },
   { sport: "Indoor Cricket", facility: "Court" },
   { sport: "Cricket Match", facility: "Ground" },
   { sport: "Street Cricket", facility: "Ground" },
   { sport: "Throwball", facility: "Court" },
   { sport: "Handball", facility: "Court" },
   { sport: "Gymming", facility: "Gym" },
   { sport: "Fun Sports", facility: "Field" },
   { sport: "Extreme", facility: "Arena" },
   { sport: "Cue Sports", facility: "Table" },
   { sport: "NetBall", facility: "Court" },
   { sport: "Box Cricket", facility: "Court" },
   { sport: "Cricket Nets", facility: "Court" },
   { sport: "Archery", facility: "Range" }
];

import React from "react";

const AboutUsHome = () => {
   const clickHandleAbout = () => {
      window.open("https://playo.co/about");
   };
   const clickHandleCareer = () => {
      window.open("https://playo.co/careers");
   };
   return (
      <section className=" p-4 md:p-14 flex flex-col gap-6 items-center justify-between md:flex-row ">
         <section className="flex flex-col gap-6 md:max-w-[526px]">
            <section className=" flex flex-col gap-2 ">
               <div className=" text-left self-stretch font-bold leading-[1.58] text-main text-2xl ">
                  About the Team
               </div>
               <div className=" text-base leading-[1.5] text-[#758a80] self-stretch">
                  Clarity gives you the blocks & components you need to create a truly professional
                  website, landing page or admin panel for your SaaS.
               </div>
            </section>
            <section className="flex flex-col md:flex-row gap-4">
               <div
                  className=" md:hidden self-stretch flex justify-center items-center py-3 px-6 shadow-[0_4px_0_0] shadow-[#00914e] rounded-2xl bg-[#00b562] font-bold text-[14px] md:text-[16px] leading-6 text-center text-[#fff] tracking-wider cursor-pointer hover:bg-green-700"
                  onClick={clickHandleCareer}
                  target="_blank"
               >
                  SEE OPENINGS{" "}
               </div>
               <div
                  className="hidden md:flex self-stretch  justify-center items-center py-3 px-6 shadow-[0_4px_0_0] shadow-[#00914e] rounded-2xl bg-[#00b562] font-bold text-[14px] md:text-[16px] leading-6 text-center text-[#fff] tracking-wider cursor-pointer hover:bg-green-700"
                  onClick={clickHandleAbout}
                  target="_blank"
               >
                  READ OUR STORY{" "}
               </div>

               <div
                  className=" md:hidden self-stretch flex justify-center items-center py-3 px-6   rounded-2xl bg-background font-bold text-[14px] md:text-base leading-6 text-center text-[#3b4540] tracking-wider border  cursor-pointer  border-main"
                  onClick={clickHandleAbout}
                  target="_blank"
               >
                  READ OUR STORY
               </div>
               <div
                  className="hidden md:flex self-stretch  justify-center items-center py-3 px-6   rounded-2xl bg-background font-bold text-[14px] md:text-base leading-6 text-center text-[#3b4540] tracking-wider border  cursor-pointer  border-main"
                  onClick={clickHandleCareer}
                  target="_blank"
               >
                  WE ARE HIRING!
               </div>
            </section>
         </section>
         <section className="flex flex-col gap-5   md:max-w-[448px] relative">
            <section className=" hidden absolute md:flex justify-center items-center w-9 h-11 -left-9 -top-5">
               <div>
                  <img
                     src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Image_Corner.png"
                     alt="About_Playo_Corner"
                     width={37}
                     height={44}
                  />
               </div>
            </section>

            <section className="flex gap-5">
               <section className="flex flex-col gap-5">
                  <div>
                     <img
                        src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Image_sitting.png"
                        alt="About_Playo"
                        width={136}
                        height={148}
                     />
                  </div>
                  <div>
                     <img
                        src="https://playo-website.gumlet.io/playo-website-v3/icons/Playo_AboutUs_Horizontal.png"
                        alt="About_Playo"
                        width={136}
                        height={43}
                     />
                  </div>
               </section>
               <section>
                  <div>
                     <img
                        src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Image.png"
                        alt="About_Playo"
                        width={293}
                        height={210}
                     />
                  </div>
               </section>
            </section>
            <section className="flex gap-5">
               <div>
                  <img
                     src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Image_Jithin.png"
                     alt="About_Playo"
                     width={119}
                     height={148}
                  />
               </div>
               <div>
                  <img
                     src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Image_Turf.png"
                     alt="About_Playo"
                     width={119}
                     height={146}
                  />
               </div>
               <div>
                  <img
                     src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Image_batting.png"
                     alt="About_Playo"
                     width={119}
                     height={148}
                  />
               </div>
               <div>
                  <img
                     src="https://playo-website.gumlet.io/playo-website-v3/icons/AboutUs_Playo_Vertical.png"
                     alt="About_Playo"
                     width={35}
                     height={148}
                  />
               </div>
            </section>
         </section>
      </section>
   );
};

export default AboutUsHome;

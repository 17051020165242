import React, { useEffect, useState } from "react";

import { IO_API_SERVICE } from "../../../services/ioApi";

import ViewAllButton from "../ViewAllButton";
import { useRouter } from "next/router";
import CarouselWithArrowsAndScroll from "../CarouselWithArrowAndScroll";
import ActivityCardV2 from "../../Card/ActivityCardV2";
import { getLocationFromLocalStorage } from "../../../util/helperFunctions";
import { UseStateValue } from "../../../state";
import { isEmpty } from "lodash";

const ActivityList = ({ latlng = {}, allSports, type }) => {
   const router = useRouter();
   const [{ location }, _] = UseStateValue();
   const [activities, setActivities] = useState({
      activityList: [],
      isLoading: false,
      isError: false
   });

   useEffect(() => {
      const storedLocation = getLocationFromLocalStorage();
      if (!isEmpty(storedLocation) || location?.locationLatLon?.length) {
         const lat = storedLocation?.lat || location?.locationLatLon[0];
         const lng = storedLocation?.lng || location?.locationLatLon[1];

         const data = {
            sort: 0,
            avgRatingSort: 0,
            lat,
            lng
         };

         setActivities({ ...activities, isLoading: true });

         IO_API_SERVICE.getHomePageActivities(data)
            .then(res => {
               // console.log({ activities: res });
               if (res.data.requestStatus === 1) {
                  setActivities({
                     activityList: res?.data?.data?.activities,
                     isLoading: false,
                     isError: false
                  });
               }
            })
            .catch(err => {
               console.error({ err });
               setActivities({
                  activityList: [],
                  isError: true,
                  isLoading: false
               });
            });
      }
   }, [location]);

   if (activities?.activityList?.length) {
      const activityProps = activities?.activityList.map(activity => ({
         activity,
         sportIcon: {
            v2GrayIcon:
               activity.sportId && allSports && allSports.list && allSports.list.length
                  ? allSports.list.find(l => l.sportId === activity.sportId)?.v2GrayIcon
                  : "https://playo-website.gumlet.io/playo-website-v2/web-home-screen/pay-join-icon.png"
         }
      }));

      const handleClick = () => {
         router.push("/activities");
      };

      return (
         <div>
            <div className={`flex justify-between font-bold  ${" md:px-12 px-4"}`}>
               <h2 className="md:leading-9 leading-8 md:text-2xl text-xl">
                  {type === "similarGames" ? " Similar Games " : "Discover Games"}
               </h2>
               <ViewAllButton text="SEE ALL GAMES" onClick={handleClick} />
            </div>
            <div className={`${"flex mt-6"}`}>
               <CarouselWithArrowsAndScroll>
                  {activityProps.map(
                     (activity, idx) =>
                        (type === "similarGames" &&
                           activity.activity.id !== router.query.activityId && (
                              <div
                                 key={idx}
                                 className={`md:ml-6  mb-6 ${"md:first:ml-12 first:ml-4 ml-4 last:pr-4 md:last:pr-12"}`}
                              >
                                 <ActivityCardV2 {...activity} />
                              </div>
                           )) ||
                        (!type && (
                           <div
                              key={idx}
                              className={`md:ml-6  mb-6 ${"md:first:ml-12 first:ml-4 ml-4 last:pr-4 md:last:pr-12"}`}
                           >
                              <ActivityCardV2 {...activity} />
                           </div>
                        ))
                  )}
               </CarouselWithArrowsAndScroll>
            </div>
         </div>
      );
   }
};

export default ActivityList;

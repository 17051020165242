import React from 'react'
import HeroImage from './HeroImage';
import RingText from './RingText';

const RightSection = () => {
  return (
    <div className='md:w-1/2 pb-4 relative'>
      <div className='absolute top-2/3 '>
        <RingText />
      </div>
      <HeroImage />
    </div>
  )
}

export default RightSection;

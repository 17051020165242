import { axiosInstance } from '../util/axiosInstance';

export const BASE_URL = '';

export const API_METHODS = {
   GET: 'GET',
   POST: 'POST',
   PUT: 'PUT',
   DELETE: 'DELETE'
};

export const httpUtils = async ({ method, url, authorization, body }) => {
   
   try {
      const res = await axiosInstance({
         method,
         url,
         data: body,
         headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
            Accept: 'application/json'
         }
      });

      if (res.status >= 200 && res.status < 300) {
         let data = res.data;
         return { data, error: null };
      }
      throw new Error(`....`);
   } catch (error) {
      return { res: null, error: error?.response || error };
   }
};

export const mapsUtil = async ({ method, url, body }) => {
   try {
      const res = await axiosInstance({
         method,
         url,
         data: body,
         headers: {
            'Content-Type': 'application/json',

            Accept: 'application/json'
         }
      });

      if (res.status >= 200 && res.status < 300) {
         let data = res.data;
         return { data, error: null };
      }
      throw new Error(`....`);
   } catch (error) {
      return { res: null, error: error?.response || error };
   }
};

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const ArrowButton = ({ disabled, onClick, children }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      disabled={disabled}
      className='transform bg-white w-11 h-11 rounded-full shadow-[0_4px_12px_0_rgba(0,0,0,0.1)] disabled:bg-surface disabled:shadow-none text-2xl flex justify-center items-center'
    >
      {children}
    </button>
  )
};

ArrowButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
};

const Carousel = ({ children }) => {
  const [currentOffset, setCurrentOffset] = useState(0);
  const [displayArrow, setDisplayArrow] = useState(true);
  const [hideArrow, setHideArrow] = useState({ left: true, right: false });
  const carouselRef = useRef(null);
  const innerRef = useRef(null);

  const handleNext = () => {
    const cardWidth = innerRef.current.firstElementChild.clientWidth;
    setHideArrow({
      left: false,
      right: false
    });
    setCurrentOffset((prevOffset) => {
      let newOffset = prevOffset - cardWidth;
      if (newOffset < -(innerRef.current.scrollWidth - carouselRef.current.clientWidth)) {
        newOffset = -(innerRef.current.scrollWidth - carouselRef.current.clientWidth);
        setHideArrow({
          left: false,
          right: true
        });
      }
      return newOffset;
    });
  };
  
  const handlePrev = () => {
    const cardWidth = innerRef.current.firstElementChild.clientWidth;
    setHideArrow({
      left: false,
      right: false
    });
    setCurrentOffset((prevOffset) => {
      let newOffset = prevOffset + cardWidth;
      if (newOffset > 0) {
        newOffset = 0;
        setHideArrow({
          left: true,
          right: false
        });
      }
      return newOffset;
    });
  };

  useEffect(() => {
    if (innerRef.current.scrollWidth === carouselRef.current.clientWidth) {
      setDisplayArrow(false);
    }
  }, [])

  return (
    <div ref={carouselRef} className='relative overflow-hidden'>
      <div ref={innerRef} style={{ transform: `translateX(${currentOffset}px)` }} className='flex transition'>
        {children}
      </div>
      {displayArrow ? <div className='flex justify-center items-center my-6 gap-3'>
        <ArrowButton onClick={handlePrev} disabled={hideArrow.left}>
          <img src='https://playo-website.gumlet.io/playo-website-v3/icons/right_arrow.png' alt='left' width={20} height={20} className='rotate-180' />
        </ArrowButton>
        <ArrowButton onClick={handleNext} disabled={hideArrow.right}>
          <img src='https://playo-website.gumlet.io/playo-website-v3/icons/right_arrow.png' alt='right' width={20} height={20} />
        </ArrowButton>
      </div> : null}
    </div>
  )
}

export default Carousel;
import React from 'react'

const HeroImage = () => {
  return (
    <div className='flex w-full items-center justify-center h-full mt-8 mb-18'>
      <img src='https://playo-website.gumlet.io/playo-website-v3/hero/hero_left.png' className='w-1/2' alt='Playo basketball' />
      <div className='relative w-1/2 flex flex-col'>
        <img src='https://playo-website.gumlet.io/playo-website-v3/hero/hero_playo_logo.png' className='w-1/4 md:w-fit absolute top-1/2 -translate-x-1/2 -translate-y-1/2' alt='Playo logo' width={'25%'} height={'100%'} />
        <img src='https://playo-website.gumlet.io/playo-website-v3/hero/hero_right_top.png' alt='Playo Badminton' width={'100%'} height={'100%'} />
        <img src='https://playo-website.gumlet.io/playo-website-v3/hero/hero_right_bottom.png' alt='Playo Football' width={'100%'} height={'100%'} />
      </div>
    </div>
  )
}

export default HeroImage;

import React from 'react'
import { PARTNER_WITH_US } from '../../constants/homePageConstants';
import Link from 'next/link';
import InfiniteScroll from '../Common/InfiniteScroll';

const PartnerWithUs = () => {
  return (
    <div>
      <h3 className='font-bold leading-9 md:mx-12 mx-4 md:text-2xl text-xl'>Corporate Partnerships</h3>
      <InfiniteScroll gap='1'>
        {PARTNER_WITH_US.PARTNER_IMAGES.map((partner, idx) => (
          <span key={idx} className='block w-full md:min-w-[360px] min-w-[200px] md:min-h-[200px] min-h-[120px]'>
            <img src={partner.imageURL} alt={partner.name} width={'100%'} height={'100%'} className='md:min-h-[200px] min-h-[120px]' />
            <p className='font-bold mt-4 ml-7 text-sm md:text-base leading-[25px] truncate'>{partner.name}</p>
          </span>
        ))}
      </InfiniteScroll>
      <div className='flex flex-wrap justify-between items-center md:px-12 my-12 gap-y-8 px-6 gap-x-2'>
        {PARTNER_WITH_US.PARTNER_LOGOS.map((logo, idx) => (
          <img src={logo.URL} width={logo.width} height={logo.height} alt={logo.name} key={idx} className='mx-auto' />
        ))}
      </div>
      <Link href={'/partner-with-us'}>
        <button className='bg-primary leading-7 w-60 h-14 new-button text-white p-2 rounded mt-12 active:border-none active:bg-green-700'>
          GET IN TOUCH
        </button>
      </Link>
    </div>
  )
}

export default PartnerWithUs;
